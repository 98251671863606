export default {
  array_required: "Required",
  array_type: "Must be an array",
  array_equals: 'Must be equal to "{{1}}"',
  array_length: 'Must have exactly "{{1}}" items',
  array_min: 'Must have at least "{{1}}" items',
  array_max: 'Must not have more than "{{1}}" items',
  array_between: 'Must have between "{{1}}" and "{{2}}" items',
  array_some_of: 'Must include some of these values "{{1}}"',
  array_none_of: 'Must not include any of these values "{{1}}"',
  boolean_required: "Required",
  boolean_type: "Must be a boolean",
  boolean_equals: 'Must be equal to "{{1}}"',
  date_required: "Required",
  date_type: "Must be a date",
  date_equals: 'Must be the same day as "{{1}}"',
  date_after: 'Must be after "{{1}}"',
  date_before: 'Must be before "{{1}}"',
  date_between: 'Must be between "{{1}}" - "{{2}}"',
  mixed_required: "Required",
  mixed_equals: 'Must be equal to "{{1}}"',
  mixed_one_of: 'Must be one of "{{1}}"',
  mixed_none_of: 'Must not be one of "{{1}}"',
  number_required: "Required",
  number_type: "Must be a number",
  number_equals: 'Must be equal to "{{1}}"',
  number_min: 'Must not be smaller than "{{1}}"',
  number_max: 'Must not be bigger than "{{1}}"',
  number_between: 'Must be between "{{1}}" and "{{2}}"',
  number_positive: "Must be a positive number",
  number_negative: "Must be a negative number",
  number_integer: "Must be an integer",
  number_one_of: 'Must be one of "{{1}}"',
  number_none_of: 'Must not be one of "{{1}}"',
  object_required: "Required",
  object_type: "Must be an object",
  object_equals: 'Must be equal to "{{1}}"',
  object_missing_key: 'Missing object key "{{1}}"',
  object_unknown_key: 'Unknown object key "{{1}}"',
  string_required: "Required",
  string_type: "Must be a string",
  string_equals: 'Must be equal to "{{1}}"',
  string_length: 'Must be "{{1}}" characters long',
  string_min: 'Must be at least "{{1}}" characters long',
  string_max: 'Must be less than "{{1}}" characters long',
  string_between: 'Must be between "{{1}}" and "{{2}}" characters long',
  string_matches: 'Must match "{{1}}"',
  string_email: "Must be an email",
  string_url: "Must be a url",
  string_starts_with: 'Must start with "{{1}}"',
  string_ends_with: 'Must end with "{{1}}"',
  string_includes: 'Must include "{{1}}"',
  string_omits: 'Must not include "{{1}}"',
  string_one_of: 'Must be one of "{{1}}"',
  string_none_of: 'Must not be one of "{{1}}"',
  string_numeric: "Must consist of digits only",
  string_alpha: "Must consist of letters only",
  string_alpha_numeric: "Must consist of letters and digits only",
  string_alpha_dashes: "Must consist of letters and dashes only",
  string_alpha_underscores: "Must consist of letters and underscores only",
  string_alpha_numeric_dashes:
    "Must consist of letters, digits and dashes only",
  string_alpha_numeric_underscores:
    "Must consist of letters, digits and underscores only",
  string_date: "Must be a date",
  string_time: "Must be a time string",
  string_date_time: "Must be a date time string",
  string_date_after: 'Must be a date after "{{1}}"',
  string_date_after_or_equal: 'Must be a date after "{{1}}"',
  string_date_before: 'Must be a date before "{{1}}"',
  string_date_before_or_equal: 'Must be a date before "{{1}}"',
  string_date_between: 'Must be a date between "{{1}}" - "{{2}}"',
  string_date_between_or_equal: 'Must be a date between "{{1}}" - "{{2}}"',
  string_time_after: 'Must be a time after "{{1}}"',
  string_time_after_or_equal: 'Must be a time after "{{1}}"',
  string_time_before: 'Must be a time before "{{1}}"',
  string_time_before_or_equal: 'Must be a time before "{{1}}"',
  string_time_between: 'Must be a time between "{{1}}" - "{{2}}"',
  string_time_between_or_equal: 'Must be a time between "{{1}}" - "{{2}}"',
}
