export default {
  array_required: "Obligatoire",
  array_type: "Doit être un tableau",
  array_equals: 'Doit être égal à "{{1}}"',
  array_length: 'Doit contenir exactement "{{1}}" éléments',
  array_min: 'Doit avoir au moins "{{1}}" éléments',
  array_max: 'Ne doit pas contenir plus d\'éléments que "{{1}}"',
  array_between: 'Doit contenir entre "{{1}}" et "{{2}}" éléments',
  array_some_of: 'Doit inclure certaines de ces valeurs "{{1}}"',
  array_none_of: 'Ne doit inclure aucune de ces valeurs "{{1}}"',
  boolean_required: "Obligatoire",
  boolean_type: "Doit être un booléen",
  boolean_equals: 'Doit être égal à "{{1}}"',
  date_required: "Obligatoire",
  date_type: "Doit être une date",
  date_equals: 'Doit être le même jour que "{{1}}"',
  date_after: 'Doit être après "{{1}}"',
  date_before: 'Doit être avant "{{1}}"',
  date_between: 'Doit être compris entre "{{1}}" et "{{2}}"',
  mixed_required: "Obligatoire",
  mixed_equals: 'Doit être égal à "{{1}}"',
  mixed_one_of: 'Doit être l\'un des "{{1}}"',
  mixed_none_of: 'Ne doit pas être l\'un des "{{1}}"',
  number_required: "Obligatoire",
  number_type: "Doit être un nombre",
  number_equals: 'Doit être égal à "{{1}}"',
  number_min: 'Ne doit pas être plus petit que "{{1}}"',
  number_max: 'Ne doit pas être plus grand que "{{1}}"',
  number_between: 'Doit être compris entre "{{1}}" et "{{2}}"',
  number_positive: "Doit être un nombre positif",
  number_negative: "Doit être un nombre négatif",
  number_integer: "Doit être un entier",
  object_required: "Obligatoire",
  object_type: "Doit être un objet",
  object_equals: 'Doit être égal à "{{1}}"',
  object_missing_key: 'Clé d\'objet manquante "{{1}}"',
  object_unknown_key: 'Clé d\'objet inconnue "{{1}}"',
  string_required: "Obligatoire",
  string_type: "Doit être une chaîne",
  string_equals: 'Doit être égal à "{{1}}"',
  string_length: 'Doit contenir "{{1}}" caractères',
  string_min: 'Doit comporter au moins "{{1}}" caractères',
  string_max: 'Doit être inférieur à "{{1}}" caractères',
  string_between: 'Doit comporter entre "{{1}}" et "{{2}}" caractères',
  string_matches: 'Doit correspondre à "{{1}}"',
  string_email: "Doit être un e-mail",
  string_url: "Doit être une URL",
  string_starts_with: 'Doit commencer par "{{1}}"',
  string_ends_with: 'Doit se terminer par "{{1}}"',
  string_includes: 'Doit inclure "{{1}}"',
  string_omits: 'Ne doit pas inclure "{{1}}"',
  string_one_of: 'Doit être l\'un des "{{1}}"',
  string_none_of: 'Ne doit pas être l\'un des "{{1}}"',
  string_numeric: "Doit être composé uniquement de chiffres",
  string_alpha: "Doit être composé uniquement de lettres",
  string_alpha_numeric:
    "Doit être composé uniquement de lettres et de chiffres",
  string_alpha_dashes: "Doit être composé uniquement de lettres et de tirets",
  string_alpha_underscores:
    "Doit être composé uniquement de lettres et de traits de soulignement",
  string_alpha_numeric_dashes:
    "Doit être composé uniquement de lettres, de chiffres et de tirets",
  string_alpha_numeric_underscores:
    "Doit être composé uniquement de lettres, de chiffres et de traits de soulignement",
  string_date: "Doit être une date",
  string_time: "Doit être une chaîne de temps",
  string_date_time: "Doit être une chaîne de date / heure",
  string_date_after: 'Doit être une date après "{{1}}"',
  string_date_after_or_equal: 'Doit être une date après "{{1}}"',
  string_date_before: 'Doit être une date antérieure à "{{1}}"',
  string_date_before_or_equal: 'Doit être une date antérieure à "{{1}}"',
  string_date_between: 'Doit être une date comprise entre "{{1}}" et "{{2}}"',
  string_date_between_or_equal:
    'Doit être une date comprise entre "{{1}}" et "{{2}}"',
  string_time_after: 'Doit être un temps après "{{1}}"',
  string_time_after_or_equal: 'Doit être un temps après "{{1}}"',
  string_time_before: 'Doit être une heure avant "{{1}}"',
  string_time_before_or_equal: 'Doit être une heure avant "{{1}}"',
  string_time_between: 'Doit être une heure entre "{{1}}" et "{{2}}"',
  string_time_between_or_equal: 'Doit être une heure entre "{{1}}" et "{{2}}"',
}
