export default {
  array_required: "Обязательно",
  array_type: "Должен быть массив",
  array_equals: 'Должно быть равно "{{1}}"',
  array_length: 'Должно быть ровно "{{1}}" элементов',
  array_min: 'Должно быть не менее "{{1}}" элементов',
  array_max: 'Должно быть не более "{{1}}" элементов',
  array_between: 'Должно быть от "{{1}}" до "{{2}}" элементов',
  array_some_of: 'Должен включать некоторые из этих значений "{{1}}"',
  array_none_of: 'Не должно включать ни одно из этих значений "{{1}}"',
  boolean_required: "Обязательно",
  boolean_type: "Должно быть логическим",
  boolean_equals: 'Должно быть равно "{{1}}"',
  date_required: "Обязательно",
  date_type: "Должно быть свидание",
  date_equals: 'Должен быть в тот же день, что и "{{1}}"',
  date_after: 'Должно быть после "{{1}}"',
  date_before: 'Должен быть перед "{{1}}"',
  date_between: 'Должно быть между "{{1}}" - "{{2}}"',
  mixed_required: "Обязательно",
  mixed_equals: 'Должно быть равно "{{1}}"',
  mixed_one_of: 'Должен быть одним из "{{1}}"',
  mixed_none_of: 'Не должно быть одним из "{{1}}"',
  number_required: "Обязательно",
  number_type: "Должно быть числом",
  number_equals: 'Должно быть равно "{{1}}"',
  number_min: 'Не должно быть меньше "{{1}}"',
  number_max: 'Не должно быть больше "{{1}}"',
  number_between: 'Должно быть между "{{1}}" и "{{2}}"',
  number_positive: "Должно быть положительное число",
  number_negative: "Должно быть отрицательное число",
  number_integer: "Должно быть целым числом",
  object_required: "Обязательно",
  object_type: "Должен быть объект",
  object_equals: 'Должно быть равно "{{1}}"',
  object_missing_key: 'Отсутствует ключ объекта "{{1}}"',
  object_unknown_key: 'Ключ неизвестного объекта "{{1}}"',
  string_required: "Обязательно",
  string_type: "Должна быть строка",
  string_equals: 'Должно быть равно "{{1}}"',
  string_length: 'Должно быть "{{1}}" симв.',
  string_min: 'Должно быть не менее "{{1}}" симв.',
  string_max: 'Должно быть меньше "{{1}}" симв.',
  string_between: 'Должно быть от "{{1}}" до "{{2}}" символов',
  string_matches: 'Должен соответствовать "{{1}}"',
  string_email: "Должен быть адрес электронной почты",
  string_url: "Должен быть URL",
  string_starts_with: 'Должен начинаться с "{{1}}"',
  string_ends_with: 'Должен заканчиваться на "{{1}}"',
  string_includes: 'Должен включать "{{1}}"',
  string_omits: 'Не должно включать "{{1}}"',
  string_one_of: 'Должен быть одним из "{{1}}"',
  string_none_of: 'Не должно быть одним из "{{1}}"',
  string_numeric: "Должен состоять только из цифр",
  string_alpha: "Должен состоять только из букв",
  string_alpha_numeric: "Должен состоять только из букв и цифр",
  string_alpha_dashes: "Должен состоять только из букв и тире",
  string_alpha_underscores: "Должен состоять только из букв и подчеркиваний",
  string_alpha_numeric_dashes: "Должен состоять только из букв, цифр и тире.",
  string_alpha_numeric_underscores:
    "Должен состоять только из букв, цифр и подчеркивания",
  string_date: "Должно быть свидание",
  string_time: "Должна быть строка времени",
  string_date_time: "Должна быть строка даты и времени",
  string_date_after: 'Дата должна быть после "{{1}}"',
  string_date_after_or_equal: 'Дата должна быть после "{{1}}"',
  string_date_before: 'Дата должна быть до "{{1}}"',
  string_date_before_or_equal: 'Дата должна быть до "{{1}}"',
  string_date_between: 'Должна быть дата между "{{1}}" - "{{2}}"',
  string_date_between_or_equal: 'Должна быть дата между "{{1}}" - "{{2}}"',
  string_time_after: 'Должно быть время после "{{1}}"',
  string_time_after_or_equal: 'Должно быть время после "{{1}}"',
  string_time_before: 'Должно быть время до "{{1}}"',
  string_time_before_or_equal: 'Должно быть время до "{{1}}"',
  string_time_between: 'Должно быть время между "{{1}}" - "{{2}}"',
  string_time_between_or_equal: 'Должно быть время между "{{1}}" - "{{2}}"',
}
