export default {
  array_required: "Necessario",
  array_type: "Deve essere un array",
  array_equals: 'Deve essere uguale a "{{1}}"',
  array_length: 'Deve contenere esattamente "{{1}}" elementi',
  array_min: 'Deve contenere almeno "{{1}}" elementi',
  array_max: 'Non deve contenere più di "{{1}}" elementi',
  array_between:
    'Deve contenere un numero di elementi compreso tra "{{1}}" e "{{2}}"',
  array_some_of: 'Deve includere alcuni di questi valori "{{1}}"',
  array_none_of: 'Non deve includere nessuno di questi valori "{{1}}"',
  boolean_required: "Necessario",
  boolean_type: "Deve essere un valore booleano",
  boolean_equals: 'Deve essere uguale a "{{1}}"',
  date_required: "Necessario",
  date_type: "Deve essere un appuntamento",
  date_equals: 'Deve essere lo stesso giorno di "{{1}}"',
  date_after: 'Deve essere dopo "{{1}}"',
  date_before: 'Deve essere prima di "{{1}}"',
  date_between: 'Deve essere compreso tra "{{1}}" - "{{2}}"',
  mixed_required: "Necessario",
  mixed_equals: 'Deve essere uguale a "{{1}}"',
  mixed_one_of: 'Deve essere uno di "{{1}}"',
  mixed_none_of: 'Non deve essere uno di "{{1}}"',
  number_required: "Necessario",
  number_type: "Deve essere un numero",
  number_equals: 'Deve essere uguale a "{{1}}"',
  number_min: 'Non deve essere inferiore a "{{1}}"',
  number_max: 'Non deve essere più grande di "{{1}}"',
  number_between: 'Deve essere compreso tra "{{1}}" e "{{2}}"',
  number_positive: "Deve essere un numero positivo",
  number_negative: "Deve essere un numero negativo",
  number_integer: "Deve essere un numero intero",
  object_required: "Necessario",
  object_type: "Deve essere un oggetto",
  object_equals: 'Deve essere uguale a "{{1}}"',
  object_missing_key: 'Manca la chiave dell\'oggetto "{{1}}"',
  object_unknown_key: 'Chiave oggetto sconosciuta "{{1}}"',
  string_required: "Necessario",
  string_type: "Deve essere una stringa",
  string_equals: 'Deve essere uguale a "{{1}}"',
  string_length: 'Deve contenere "{{1}}" caratteri',
  string_min: 'Deve contenere almeno "{{1}}" caratteri',
  string_max: 'Deve contenere meno di "{{1}}" caratteri',
  string_between:
    'Deve contenere un numero di caratteri compreso tra "{{1}}" e "{{2}}"',
  string_matches: 'Deve corrispondere a "{{1}}"',
  string_email: "Deve essere un'e-mail",
  string_url: "Deve essere un URL",
  string_starts_with: 'Deve iniziare con "{{1}}"',
  string_ends_with: 'Deve terminare con "{{1}}"',
  string_includes: 'Deve includere "{{1}}"',
  string_omits: 'Non deve includere "{{1}}"',
  string_one_of: 'Deve essere uno di "{{1}}"',
  string_none_of: 'Non deve essere uno di "{{1}}"',
  string_numeric: "Deve essere composto solo da cifre",
  string_alpha: "Deve essere composto solo da lettere",
  string_alpha_numeric: "Deve essere composto solo da lettere e cifre",
  string_alpha_dashes: "Deve essere composto solo da lettere e trattini",
  string_alpha_underscores:
    "Deve essere composto solo da lettere e trattini bassi",
  string_alpha_numeric_dashes:
    "Deve essere composto solo da lettere, cifre e trattini",
  string_alpha_numeric_underscores:
    "Deve essere composto solo da lettere, cifre e trattini bassi",
  string_date: "Deve essere un appuntamento",
  string_time: "Deve essere una stringa di tempo",
  string_date_time: "Deve essere una stringa di data e ora",
  string_date_after: 'Deve essere una data successiva a "{{1}}"',
  string_date_after_or_equal: 'Deve essere una data successiva a "{{1}}"',
  string_date_before: 'Deve essere una data precedente a "{{1}}"',
  string_date_before_or_equal: 'Deve essere una data precedente a "{{1}}"',
  string_date_between: 'Deve essere una data compresa tra "{{1}}" e "{{2}}"',
  string_date_between_or_equal:
    'Deve essere una data compresa tra "{{1}}" e "{{2}}"',
  string_time_after: 'Deve essere un\'ora dopo "{{1}}"',
  string_time_after_or_equal: 'Deve essere un\'ora dopo "{{1}}"',
  string_time_before: 'Deve essere un\'ora prima di "{{1}}"',
  string_time_before_or_equal: 'Deve essere un\'ora prima di "{{1}}"',
  string_time_between: 'Deve essere un tempo compreso tra "{{1}}" e "{{2}}"',
  string_time_between_or_equal:
    'Deve essere un tempo compreso tra "{{1}}" e "{{2}}"',
}
