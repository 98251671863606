export default {
  array_required: "Necesario",
  array_type: "Debe ser una matriz",
  array_equals: 'Debe ser igual a "{{1}}"',
  array_length: 'Debe tener exactamente "{{1}}" elementos',
  array_min: 'Debe tener al menos "{{1}}" elementos',
  array_max: 'No debe tener más de "{{1}}" elementos',
  array_between: 'Debe tener entre "{{1}}" y "{{2}}" elementos',
  array_some_of: 'Debe incluir algunos de estos valores "{{1}}"',
  array_none_of: 'No debe incluir ninguno de estos valores "{{1}}"',
  boolean_required: "Necesario",
  boolean_type: "Debe ser un booleano",
  boolean_equals: 'Debe ser igual a "{{1}}"',
  date_required: "Necesario",
  date_type: "Debe ser una cita",
  date_equals: 'Debe ser el mismo día que "{{1}}"',
  date_after: 'Debe ser posterior a "{{1}}"',
  date_before: 'Debe ser anterior a "{{1}}"',
  date_between: 'Debe estar entre "{{1}}" - "{{2}}"',
  mixed_required: "Necesario",
  mixed_equals: 'Debe ser igual a "{{1}}"',
  mixed_one_of: 'Debe ser uno de "{{1}}"',
  mixed_none_of: 'No debe ser uno de "{{1}}"',
  number_required: "Necesario",
  number_type: "Tiene que ser un número",
  number_equals: 'Debe ser igual a "{{1}}"',
  number_min: 'No debe ser más pequeño que "{{1}}"',
  number_max: 'No debe ser más grande que "{{1}}"',
  number_between: 'Debe estar entre "{{1}}" y "{{2}}"',
  number_positive: "Debe ser un número positivo",
  number_negative: "Debe ser un número negativo",
  number_integer: "Debe ser un entero",
  object_required: "Necesario",
  object_type: "Debe ser un objeto",
  object_equals: 'Debe ser igual a "{{1}}"',
  object_missing_key: 'Falta la clave de objeto "{{1}}"',
  object_unknown_key: 'Clave de objeto desconocida "{{1}}"',
  string_required: "Necesario",
  string_type: "Debe ser una cuerda",
  string_equals: 'Debe ser igual a "{{1}}"',
  string_length: 'Debe tener "{{1}}" caracteres',
  string_min: 'Debe tener al menos "{{1}}" caracteres',
  string_max: 'Debe tener menos de "{{1}}" caracteres',
  string_between: 'Debe tener entre "{{1}}" y "{{2}}" caracteres',
  string_matches: 'Debe coincidir con "{{1}}"',
  string_email: "Debe ser un correo electrónico",
  string_url: "Debe ser una URL",
  string_starts_with: 'Debe comenzar con "{{1}}"',
  string_ends_with: 'Debe terminar con "{{1}}"',
  string_includes: 'Debe incluir "{{1}}"',
  string_omits: 'No debe incluir "{{1}}"',
  string_one_of: 'Debe ser uno de "{{1}}"',
  string_none_of: 'No debe ser uno de "{{1}}"',
  string_numeric: "Debe constar solo de dígitos",
  string_alpha: "Debe constar solo de letras",
  string_alpha_numeric: "Debe constar únicamente de letras y dígitos",
  string_alpha_dashes: "Debe constar únicamente de letras y guiones",
  string_alpha_underscores: "Debe constar únicamente de letras y guiones bajos",
  string_alpha_numeric_dashes:
    "Debe constar únicamente de letras, dígitos y guiones.",
  string_alpha_numeric_underscores:
    "Debe constar únicamente de letras, dígitos y guiones bajos",
  string_date: "Debe ser una cita",
  string_time: "Debe ser una cadena de tiempo",
  string_date_time: "Debe ser una cadena de fecha y hora",
  string_date_after: 'Debe ser una fecha posterior a "{{1}}"',
  string_date_after_or_equal: 'Debe ser una fecha posterior a "{{1}}"',
  string_date_before: 'Debe ser una fecha anterior a "{{1}}"',
  string_date_before_or_equal: 'Debe ser una fecha anterior a "{{1}}"',
  string_date_between: 'Debe ser una fecha entre "{{1}}" - "{{2}}"',
  string_date_between_or_equal: 'Debe ser una fecha entre "{{1}}" - "{{2}}"',
  string_time_after: 'Debe ser un tiempo posterior a "{{1}}"',
  string_time_after_or_equal: 'Debe ser un tiempo posterior a "{{1}}"',
  string_time_before: 'Debe ser un tiempo antes de "{{1}}"',
  string_time_before_or_equal: 'Debe ser un tiempo anterior a "{{1}}"',
  string_time_between: 'Debe ser un tiempo entre "{{1}}" - "{{2}}"',
  string_time_between_or_equal: 'Debe ser un tiempo entre "{{1}}" - "{{2}}"',
}
