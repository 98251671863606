export default {
  array_required: "Erforderlich",
  array_type: "Muss ein Array sein",
  array_equals: 'Muss gleich "{{1}}" sein',
  array_length: 'Muss genau "{{1}}" Elemente enthalten',
  array_min: 'Muss mindestens "{{1}}" Elemente enthalten',
  array_max: 'Darf nicht mehr als "{{1}}" Elemente enthalten',
  array_between: 'Muss zwischen "{{1}}" und "{{2}}" Elementen liegen',
  array_some_of: 'Muss einige dieser Werte enthalten "{{1}}"',
  array_none_of: 'Darf keinen dieser Werte "{{1}}" enthalten.',
  boolean_required: "Erforderlich",
  boolean_type: "Muss ein Boolescher Wert sein",
  boolean_equals: 'Muss gleich "{{1}}" sein',
  date_required: "Erforderlich",
  date_type: "Muss ein Datum sein",
  date_equals: 'Muss am selben Tag sein wie "{{1}}"',
  date_after: 'Muss nach "{{1}}" stehen',
  date_before: 'Muss vor "{{1}}" stehen',
  date_between: 'Muss zwischen "{{1}}" - "{{2}}" liegen',
  mixed_required: "Erforderlich",
  mixed_equals: 'Muss gleich "{{1}}" sein',
  mixed_one_of: 'Muss einer von "{{1}}" sein',
  mixed_none_of: 'Darf nicht einer von "{{1}}" sein',
  number_required: "Erforderlich",
  number_type: "Muss eine Nummer sein",
  number_equals: 'Muss gleich "{{1}}" sein',
  number_min: 'Darf nicht kleiner sein als "{{1}}"',
  number_max: 'Darf nicht größer sein als "{{1}}"',
  number_between: 'Muss zwischen "{{1}}" und "{{2}}" liegen',
  number_positive: "Muss eine positive Zahl sein",
  number_negative: "Muss eine negative Zahl sein",
  number_integer: "Muss eine ganze Zahl sein",
  object_required: "Erforderlich",
  object_type: "Muss ein Objekt sein",
  object_equals: 'Muss gleich "{{1}}" sein',
  object_missing_key: 'Fehlender Objektschlüssel "{{1}}"',
  object_unknown_key: 'Unbekannter Objektschlüssel "{{1}}"',
  string_required: "Erforderlich",
  string_type: "Muss eine Zeichenfolge sein",
  string_equals: 'Muss gleich "{{1}}" sein',
  string_length: 'Muss "{{1}}" Zeichen lang sein',
  string_min: 'Muss mindestens "{{1}}" Zeichen lang sein',
  string_max: 'Muss kürzer als "{{1}}" Zeichen sein',
  string_between: 'Muss zwischen "{{1}}" und "{{2}}" Zeichen lang sein',
  string_matches: 'Muss mit "{{1}}" übereinstimmen',
  string_email: "Muss eine E-Mail sein",
  string_url: "Muss eine URL sein",
  string_starts_with: 'Muss mit "{{1}}" beginnen',
  string_ends_with: 'Muss mit "{{1}}" enden',
  string_includes: 'Muss "{{1}}" enthalten',
  string_omits: 'Darf nicht "{{1}}" enthalten',
  string_one_of: 'Muss einer von "{{1}}" sein',
  string_none_of: 'Darf nicht einer von "{{1}}" sein',
  string_numeric: "Darf nur aus Ziffern bestehen",
  string_alpha: "Darf nur aus Buchstaben bestehen",
  string_alpha_numeric: "Darf nur aus Buchstaben und Ziffern bestehen",
  string_alpha_dashes: "Darf nur aus Buchstaben und Bindestrichen bestehen",
  string_alpha_underscores:
    "Darf nur aus Buchstaben und Unterstrichen bestehen",
  string_alpha_numeric_dashes:
    "Darf nur aus Buchstaben, Ziffern und Bindestrichen bestehen",
  string_alpha_numeric_underscores:
    "Darf nur aus Buchstaben, Ziffern und Unterstrichen bestehen",
  string_date: "Muss ein Datum sein",
  string_time: "Muss eine Zeitzeichenfolge sein",
  string_date_time: "Muss eine Datums- / Zeitzeichenfolge sein",
  string_date_after: 'Muss ein Datum nach "{{1}}" sein',
  string_date_after_or_equal: 'Muss ein Datum nach "{{1}}" sein',
  string_date_before: 'Muss ein Datum vor "{{1}}" sein',
  string_date_before_or_equal: 'Muss ein Datum vor "{{1}}" sein',
  string_date_between: 'Muss ein Datum zwischen "{{1}}" - "{{2}}" sein.',
  string_date_between_or_equal:
    'Muss ein Datum zwischen "{{1}}" - "{{2}}" sein.',
  string_time_after: 'Muss eine Zeit nach "{{1}}" sein',
  string_time_after_or_equal: 'Muss eine Zeit nach "{{1}}" sein',
  string_time_before: 'Muss eine Zeit vor "{{1}}" sein',
  string_time_before_or_equal: 'Muss eine Zeit vor "{{1}}" sein',
  string_time_between: 'Muss eine Zeit zwischen "{{1}}" - "{{2}}" sein',
  string_time_between_or_equal:
    'Muss eine Zeit zwischen "{{1}}" - "{{2}}" sein',
}
